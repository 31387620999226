import Cookies from "js-cookie";
import { getMasterDataBylabel } from "../apiCalls/ApiCalls";

export function decodeJWT(token) {
  const decodedPayload = JSON.parse(atob(token.split(".")[1]));
  return decodedPayload;
}

export function getAccessFromToken() {
  const payload = decodeJWT(getLocalAccessToken());
  const { Access, FullName, EmpId, Email, Id } = payload;
  console.log(payload);
  return { SpaceName: Access, FullName, EmpId, Email, Id };
}

export function getLocalAccessToken() {
  const token = Cookies.get("jwtToken");
  return token;
}
export async function fetchData(category) {
  const { SpaceName } = getAccessFromToken();
  const spaceAdminSpaces = SpaceName?.filter((accessRole) =>
    accessRole.includes(category)
  );
  if (spaceAdminSpaces.length === 0) {
    console.error(`No ${category} roles found`);
    return [];
  }
  let formattedSpace;
  if (category === "Space-Admin") {
    formattedSpace = spaceAdminSpaces[0];
    try {
      const response = await getMasterDataBylabel(formattedSpace);
      return response.data;
    } catch (error) {
      console.error(`Error fetching ${category} master data:`, error);
      throw error;
    }
  } else {
    formattedSpace = spaceAdminSpaces;

    return formattedSpace;
  }
}
export function redirectOnAccess() {
  const { Access } = getAccessFromToken();

  Access.includes("Space Admin" || "Super Admin")
    ? (window.location.href = "/")
    : (window.location.href = process.env.REACT_APP_GPT_UI_URL);
}

export function canAccess() {
  const { Access } = getAccessFromToken();
  return Access.includes("End User") ? true : false;
}

export async function checkForAuthorization() {
  const formattedSpaceData = await fetchData("End-User");
  if (formattedSpaceData.length === 0) {
    window.location.href = "/unauthorized";
  }
}
