import React from "react";
import { Box, Typography } from "@mui/material";
import unauthorized from "../../src/assets/images/unauthorizedlogo.svg";

const UnauthorizedLayout = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <img
        src={unauthorized}
        alt="unauthorized logo"
        style={{ width: "20%" }}
      />

      <h1>Access Denied</h1>
      <Typography
        gutterBottom
        variant="h6"
        maxWidth={"40vw"}
        textAlign={"center"}
      >
        We're sorry, but you are not authorized to view this page. Please
        contact the administrator for assistance.
      </Typography>
      {/* <Typography variant="body1">
        <span
          onClick={redirectOnAccess}
          style={{
            fontWeight: "bolder",
            textDecorationLine: "blink",
            cursor: "pointer",
            textDecoration: "underline",
            textDecorationColor: "blue",
          }}
        >
          Click here
        </span>{" "}
        to go back to home
      </Typography> */}
    </Box>
  );
};

export default UnauthorizedLayout;
