// export const endPoints = {
//   generateOtp: "/login/send-otp",
//   verifyOtp: "/login/verify-otp",
//   uploadDocument: "/upload/documents",
//   getDocuments: "/space/documents",
//   deleteDocument: "/documents/delete",
//   gpt: "/qa",
//   profile: "/users",
//   spaceAdminUsers: "/endusers/space",
//   allUsers: "/endusers",
// };

export const endPoints = {
  generateOtp: "/login/send-otp",
  verifyOtp: "/login/verify-otp",
  uploadDocument: "/upload/documents",
  getDocuments: "/space/documents",
  deleteDocument: "/delete/document",
  gptQa: "/qa",
  gptDb: "/ask_question",
  profile: "/users",
  spaceAdminUsers: "space-admin",
  usersOfSpace: "/endusers/space",
  allUsers: "/endusers",
  updateUserAccess: "/users/update/access",
  removeUser: "/users/delete/space",
  // auth-x
  getMasterData: "/masterdata",
  getMasterDatalabel: "/masterdata/label",
  getMutipleMasterDatalabel: "/masterdata/labels",
  getAllLanguages: "/getAllLanguages",
  fetchUser: "/users",
};
