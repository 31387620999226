import { toast } from "react-toastify";
import { endPoints } from "./apiEndpoints";
import { axiosInstance, axiosInstanceAuthx } from "./logicService";
import { aiAxiosInstance } from "./aiService";
import { TOAST_MESSAGES } from "../utilities/constants";
import Cookies from "js-cookie";
export const sendOtpApi = async (email) => {
  try {
    const response = await axiosInstance.post(`${endPoints.generateOtp}`, {
      email: email,
    });

    return response;
  } catch (error) {
    toast.error(TOAST_MESSAGES.NETWORK_ERROR);
  }
};

export const otpValidation = async (loginDetails) => {
  try {
    const response = await axiosInstance.post(
      `${endPoints.verifyOtp}`,
      loginDetails
    );
    return response;
  } catch (error) {
    toast.error(TOAST_MESSAGES.FAILURE_VERIFY_OTP);
    return error;
  }
};

export const gptUiQuestionAndAnswer = async (gptDetails, source) => {
  try {
    const response = await aiAxiosInstance.post(
      `${source === "DB Data" ? endPoints.gptDb : endPoints.gptQa}`,
      gptDetails
    );
    return response;
  } catch {
    toast.error(TOAST_MESSAGES.AI_RESPONSE_ERROR);
  }
};

export const getUserProfile = async (EmpId) => {
  try {
    return await axiosInstance.get(`${endPoints.profile}/${EmpId}`);
  } catch (error) {
    toast.error(TOAST_MESSAGES.USER_DETAILS_ERROR);
  }
};

export const uploadDocApi = async (docDetails) => {
  try {
    const response = await axiosInstance.post(
      `${endPoints.uploadDocument}`,
      docDetails
    );
    toast.success(TOAST_MESSAGES.SUCCESS_UPLOAD_DOCUMENT);
    return response;
  } catch (error) {
    toast.error(TOAST_MESSAGES.FAILURE_UPLOAD_DOCUMENT);
  }
};

export const getDocApi = async (getDocDetails) => {
  const { access, selectedSpace } = getDocDetails;
  try {
    const response = await axiosInstance.get(
      `${endPoints.getDocuments}?access=${access}&space=${selectedSpace}`
    );
    return response;
  } catch (error) {
    toast.error(TOAST_MESSAGES.FAILURE_GET_DOCUMENTS);
  }
};
export const delDocApi = async (id) => {
  try {
    const response = await axiosInstance.delete(
      `${endPoints.deleteDocument}/${id}`
    );
    return response;
  } catch (error) {
    toast.error(TOAST_MESSAGES.FAILURE_DELETE_DOCUMENT);
  }
};

export const getSpaceAdmins = async (userDetails) => {
  const { space } = userDetails;

  try {
    const response = await axiosInstance.get(
      `${endPoints.spaceAdminUsers}/${space}`
    );
    return response.data;
  } catch (error) {
    toast.error(TOAST_MESSAGES.FAILURE_GET_USERS);
  }
};

export const getSpaceUsers = async (spaceName) => {
  console.log(spaceName);
  try {
    const response = await axiosInstance.get(
      `${endPoints.usersOfSpace}/${spaceName}`
    );
    return response.data;
  } catch (error) {
    toast.error(TOAST_MESSAGES.FAILURE_GET_USERS);
  }
};

export const getAllUsers = async () => {
  try {
    const response = await axiosInstance.get(`${endPoints.allUsers}`);
    return response.data;
  } catch (error) {
    toast.error(TOAST_MESSAGES.FAILURE_GET_USERS);
  }
};

export const updateUserSpace = async (data, empId) => {
  try {
    const response = await axiosInstance.put(
      `${endPoints.updateUserAccess}/${empId}`,
      data
    );
    return response;
  } catch (error) {
    toast.error(TOAST_MESSAGES.ERROR);
  }
};

export const removeUserAccess = async (empId, spaceName) => {
  try {
    const response = await axiosInstance.delete(
      `${endPoints.removeUser}/${empId}/${spaceName}`
    );
    return response;
  } catch (error) {
    toast.error(TOAST_MESSAGES.ERROR);
  }
};

export const getMasterDataForSpaceNames = async (category) => {
  try {
    let config = {
      headers: {
        Authorization: Cookies.get("jwtToken"),
        "Content-Type": "application/json",
      },
    };

    const response = await axiosInstanceAuthx.get(
      `${endPoints.getMasterData}/${category}`,
      config
    );
    return response;
  } catch (error) {
    toast.error("Failed to get spaces details");
  }
};
export const getMasterDataBylabel = async (category) => {
  try {
    let config = {
      headers: {
        Authorization: Cookies.get("jwtToken"),
        "Content-Type": "application/json",
      },
    };

    const response = await axiosInstanceAuthx.get(
      `${endPoints.getMasterDatalabel}/${category}`,
      config
    );

    return response;
  } catch (error) {
    toast.error("Failed to get spaces details");
  }
};
export const getUserDetials = async (empid) => {
  try {
    let config = {
      headers: {
        Authorization: Cookies.get("jwtToken"),
        "Content-Type": "application/json",
      },
    };

    const response = await axiosInstanceAuthx.get(
      `${endPoints.fetchUser}/${empid}`,
      config
    );

    return response;
  } catch (error) {
    toast.error("Failed to get spaces details");
  }
};
export const getmasterdatalabels = async (labels) => {
  try {
    let config = {
      headers: {
        Authorization: Cookies.get("jwtToken"),
        "Content-Type": "application/json",
      },
    };

    const response = await axiosInstanceAuthx.get(
      `${endPoints.getMutipleMasterDatalabel}/${labels}`,
      config
    );

    return response;
  } catch (error) {
    toast.error("Failed to get spaces details");
  }
};

export const getAllLanguages = async (selectedLanguage) => {
  try {
    const response = await axiosInstance.get(
      `${endPoints.getAllLanguages}/${selectedLanguage}`
    );
    if (response?.status === 200) {
    }
    return response?.data;
  } catch (error) {
    toast.error("Failed to get languages");
  }
};
