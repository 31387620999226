// src/components/TranslationsContext.js
import React, { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import { getAllLanguages } from "../apiCalls/ApiCalls"; // Make sure this imports the right function

export const TranslationsContext = createContext();

export const TranslationsProvider = ({ children }) => {
  const [translations, setTranslations] = useState(() => {
    const cookieValue = Cookies.get("translatedWords");
    return cookieValue ? JSON.parse(cookieValue) : {};
  });

  const loadTranslations = async (language) => {
    try {
      const allLanguages = await getAllLanguages(language);
      setTranslations(allLanguages);
    } catch (error) {
      console.error("Error fetching translations:", error);
    }
  };

  useEffect(() => {
    const language = Cookies.get("selectedLanguage") || "English";
    loadTranslations(language);
  }, []);

  const setLanguage = async (language) => {
    Cookies.set("selectedLanguage", language, { expires: 30 });
    await loadTranslations(language);
  };

  return (
    <TranslationsContext.Provider value={{ translations, setLanguage }}>
      {children}
    </TranslationsContext.Provider>
  );
};
