import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Grid,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Button,
  Drawer,
  IconButton,
  Stack,
} from "@mui/material";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import "./TextFieldWidthIcon.css";
import History from "./History";
import "./gptStyles.css";
import { gptUiQuestionAndAnswer } from "../../apiCalls/ApiCalls";
import { isEmpty } from "lodash";
import GptAssist from "./GptAssist";
import sendicon from "../../assets/images/sendIcon.png";
import {
  checkForAuthorization,
  getAccessFromToken,
} from "../../utilities/tokenUtils";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import toggleButton from "../../assets/images/sidebar-simple.svg";
import SourceToggle from "../helpers/SourceToggle";
import { TranslationsContext } from "../TranslationsContext";

const GptUi = ({ setAppTheme }) => {
  const { translations } = useContext(TranslationsContext);
  const chatHistory = JSON.parse(sessionStorage.getItem("chatHistory"));
  const userTheme = JSON.parse(localStorage.getItem("userTheme"));
  const [questions, setQuestions] = useState("");
  const [open, setOpen] = React.useState(false);
  const [source, setSource] = useState("Text Data");

  const handleSourceControl = (e) => {
    setSource(e.target.value);
  };
  const toggleDrawer = (newOpen) => {
    setOpen(newOpen);
  };
  const [history, setHistory] = useState(
    chatHistory !== null ? chatHistory : []
  );
  const chatHistoryRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [themeColor, setThemeColor] = useState(
    userTheme !== null ? userTheme.themeColor : "#eeffe9"
  );
  const [themeDarkColor, setThemeDarkColor] = useState(
    userTheme !== null ? userTheme.themeDarkColor : "#84C101"
  );

  useEffect(() => {
    if (themeColor === "#424242") {
      setAppTheme("dark");
    } else {
      setAppTheme("light");
    }
    localStorage.setItem(
      "userTheme",
      JSON.stringify({ themeColor, themeDarkColor })
    );
    // eslint-disable-next-line
  }, [themeColor]);

  const setTheme = (value) => {
    switch (value) {
      case "Orange":
        setThemeDarkColor("#FF9A51");
        return setThemeColor("#FFE3C8");
      case "Lavender":
        setThemeDarkColor("#7800B3");
        return setThemeColor("#D4D8FF");
      case "Green":
        setThemeDarkColor("#84C101");
        return setThemeColor("#eeffe9");
      case "Dark":
        setThemeDarkColor("#ffffff");
        return setThemeColor("#424242");
      case undefined:
        return setThemeColor("#F5FEE6");
      default:
        return setThemeColor("#eeffe9");
    }
  };

  useEffect(() => {
    sessionStorage.setItem("chatHistory", JSON.stringify(history));
  }, [history]);

  useEffect(() => {
    checkForAuthorization();
  }, []);

  const handleQuestions = async (event) => {
    if (questions?.trim() !== "" && !loading) {
      event.preventDefault();
      setLoading(true);
      setQuestions("");
      const newHistory = { question: questions, answer: "" };
      setHistory([newHistory, ...history]);
      const { SpaceName } = getAccessFromToken();
      const options = {
        question: questions?.trim(),
        spacename: SpaceName,
      };
      const sqlPayload = {
        question: questions?.trim(),
      };
      const response = await gptUiQuestionAndAnswer(
        source === "DB Data" ? sqlPayload : options,
        source
      );
      console.log(response, "RESPONSE");
      if (response) {
        newHistory.answer =
          source === "DB Data" ? response.data : response.data.answer;
        setHistory([newHistory, ...history]);
        setLoading(false);
      } else {
        setLoading(false);
        newHistory.answer = "Something went wrong. Please try after sometime";
        setHistory([newHistory, ...history]);
      }
    } else {
      event.preventDefault();
    }
  };

  const theme = useTheme();
  const isHistory = useMediaQuery(theme.breakpoints.down("xs"));
  const [anchorgpt, setAnchorgpt] = useState(null);

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          background: "#ffffff",
          height: "100vh",
          width: "60%",
          borderRadius: 2,
          boxSizing: "border-box",
          marginLeft: open ? "330px" : 0,
          transition: "margin-left 0.5s ease",
        }}
      >
        {/* History */}
        {isEmpty(history) ? (
          <Box
            sx={{
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <GptAssist
              themeColor={themeColor}
              themeDarkColor={themeDarkColor}
            />
          </Box>
        ) : (
          <Box
            sx={{
              overflowY: "auto",
              mt: 2,
              // ml: -4,
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column-reverse",
              height: "80vh",
              "&::-webkit-scrollbar": {
                display: "none",
                width: "10px",
                background: "#fff",
              },
            }}
            ref={chatHistoryRef}
          >
            {/* Loader */}
            <Box>
              {loading && (
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    mb: 2,
                  }}
                >
                  <AutoAwesomeIcon
                    sx={{
                      width: "30px",
                      height: "30px",
                      // border: "1px solid #9D9D9D",
                      // borderRadius: "50%",
                      alignSelf: "flex-start",
                      p: 0.4,
                      boxSizing: "border-box",
                      // color:
                      //   themeDarkColor !== "#ffffff"
                      //     ? themeDarkColor
                      //     : "#000000",
                    }}
                  />
                  <div className="loader"></div>
                </Grid>
              )}
            </Box>

            {/* History */}
            {history.map((each, index) => {
              console.log(each, "EACH");

              return (
                <Box key={index}>
                  {/* question */}
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 1,
                      mb: 1,
                    }}
                  >
                    <Person2OutlinedIcon
                      sx={{
                        // border: "1px solid #9D9D9D",
                        // borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                        padding: 0.3,
                        boxSizing: "border-box",
                      }}
                    />

                    <Box
                      key={index}
                      width={"90%"}
                      sx={{
                        fontFamily: "work sans",
                        // border: "1px solid #C1C1C1",
                        // borderRadius: "8px",
                        margin: "8px 28px",
                        marginLeft: 0,
                        maxWidth: "max-content",
                        padding: "8px",
                        pl: 0,
                        msWordBreak: "break-word",
                      }}
                    >
                      <Typography
                        variant="body"
                        sx={{ maxWidth: "50%", wordWrap: "break-word" }}
                      >
                        {each.question}
                      </Typography>
                    </Box>
                  </Grid>
                  {/* answer */}
                  {each.answer !== "" && (
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        mb: 4,
                      }}
                    >
                      <AutoAwesomeIcon
                        sx={{
                          width: "30px",
                          height: "30px",
                          // border: "1px solid #9D9D9D",
                          // borderRadius: "50%",
                          alignSelf: "flex-start",
                          p: 0.4,
                          boxSizing: "border-box",
                          // color:
                          //   themeDarkColor !== "#ffffff"
                          //     ? themeDarkColor
                          //     : "#000000",
                        }}
                      />
                      <Box
                        key={index}
                        sx={{
                          fontFamily: "work sans",
                          borderRadius: 1,
                          // borderLeft: "5px solid #9D9D9D",
                          borderLeft: `5px solid ${
                            themeDarkColor !== "#ffffff"
                              ? themeDarkColor
                              : "#000000"
                          }`,
                          marginLeft: 0,
                          // maxWidth: "80%",
                          minWidth: "min-content",
                          // bgcolor: "#F6F6F6",
                          height: "auto",
                          padding: "0px 8px",
                          display: "inline-block",
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{ __html: each.answer }}
                        ></div>
                      </Box>
                    </Grid>
                  )}
                </Box>
              );
            })}
          </Box>
        )}

        {/* Input box */}
        <Box
          component={"form"}
          onSubmit={handleQuestions}
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor: "#F6F6F6",
            borderRadius: 4,
            p: 1,
            pl: 2,
            mt: 1,
            // border: `1px solid ${themeDarkColor}`,
            // boxShadow: `2px 4px 10px 0px ${themeColor}`,
            // boxShadow: `2px 4px 10px 0px #bfbfbf`,
          }}
        >
          <input
            autoFocus
            type="text"
            onChange={(e) => setQuestions(e.target.value)}
            value={questions}
            placeholder={translations?.type_here || "Type here..."}
            className="text-field"
            style={{ color: "#606060" }}
          />
          {loading ? (
            <StopCircleOutlinedIcon
              sx={{ height: "36px", width: "36px", p: 0.5, pr: 1.5 }}
            />
          ) : (
            <Button
              type="submit"
              disableFocusRipple
              disableRipple
              sx={{ "&:hover": { backgroundColor: "unset" } }}
            >
              <img
                src={sendicon}
                alt="send-icon"
                className={questions !== "" && !loading ? "icon-empty" : "icon"}
              />
            </Button>
          )}
        </Box>
      </Box>

      {/* Slider Component */}
      <Drawer
        open={open}
        onClose={() => toggleDrawer(false)}
        variant="persistent"
      >
        <History
          themeDarkColor={themeDarkColor}
          setTheme={setTheme}
          color={themeColor}
          isHistory={isHistory}
          anchorgpt={anchorgpt}
          setAnchorgpt={setAnchorgpt}
          toggleDrawer={toggleDrawer}
        />
      </Drawer>

      {/* Toggle Button */}
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={2}
        sx={{
          position: "fixed",
          left: open ? "240px" : "30px",
          top: "25px",
          transition: "left 0.2s ease",
          borderRadius: "50%",
          // zIndex: "10000",
        }}
      >
        {!open && (
          <IconButton
            title="Open Sidebar"
            disableRipple
            onClick={() => toggleDrawer(true)}
          >
            <img
              src={toggleButton}
              alt="toggle-button"
              style={{ width: "28px", height: "28px" }}
            />
          </IconButton>
        )}
        {!open && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "text.primary",
              gap: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: 700,
                fontFamily: "Oxygen",
                color: "#000000",
              }}
            >
              Nexus
            </Typography>
            <AutoAwesomeIcon
              sx={{
                color:
                  themeDarkColor !== "#ffffff" ? themeDarkColor : "#424242",
                width: "22px",
                height: "22px",
              }}
            />
          </Box>
        )}
      </Stack>
      <Box
        sx={{
          // position: "absolute",
          right: open ? "60%" : "70%",
          transition: "right 0.5s ease",
          mt: "25px",
          // mt: -1,
        }}
      >
        <Stack
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography
            textAlign={"center"}
            // variant="caption"
            fontWeight={"bold"}
          >
            {translations?.select_source || "Select Source"}
          </Typography>
          <SourceToggle handleSource={handleSourceControl} source={source} />
        </Stack>
      </Box>
    </Box>
  );
};

export default GptUi;
