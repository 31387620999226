import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";

const SourceToggle = ({ handleSource, source }) => {
  return (
    <FormControl size="small" fullWidth>
      <Select size="small" value={source} onChange={handleSource}>
        <MenuItem value={"Text Data"}>Text Data</MenuItem>
        <MenuItem value={"DB Data"}>DB Data</MenuItem>
      </Select>
    </FormControl>
  );
};

export default SourceToggle;
