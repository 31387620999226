const API_STATUS = {
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
};
const API_TIMEOUT = 60000;

const API_RESPONSE_CODES = {
  SUCCESS: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
};
const TOAST_MESSAGES = {
  SUCCESS: "Success!",
  ERROR: "An error occurred. Please try again later.",
  AI_RESPONSE_ERROR: "Something went wrong. Please try again later",
  NETWORK_ERROR: "Network error. Please check your internet connection.",
  LOGIN_SUCCESS: "Login successful!",
  LOGOUT_SUCCESS: "Logout successful!",
  PASSWORD_CHANGED: "Password changed successfully!",
  USER_DETAILS_ERROR: "Failed to get user details",
  FAILURE_UPLOAD_DOCUMENT: "Failed to Upload document. Please try again later.",
  SUCCESS_UPLOAD_DOCUMENT: "Document uploaded successfully!",
  FAILURE_GET_DOCUMENTS: "Failed to get documents.",
  FAILURE_DELETE_DOCUMENT: "Failed to delete document. Please try again later.",
  FAILURE_GET_USERS: "Failed to get users. Please try again later.",
  FAILURE_VERIFY_OTP: "Failed to verify OTP. Please try again later.",
};

export { API_STATUS, API_RESPONSE_CODES, TOAST_MESSAGES, API_TIMEOUT };
