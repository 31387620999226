import { Box, Typography } from "@mui/material";
import React from "react";

const NotFoundComponent = (props) => {
  return (
    <Box
      sx={{
        backgroundColor: "#D9E1FF",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Typography variant="h2">404 Not Found</Typography>
      <Typography variant="h4">
        Oops! The page you're looking for doesn't exist.
      </Typography>
      <Typography variant="h6">
        Please go back to the <a href="/">homepage</a>.
      </Typography>
    </Box>
  );
};
export default NotFoundComponent;
