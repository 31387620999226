import React, { useEffect, useState, useContext } from "react";
import {
  Grid,
  Typography,
  Box,
  Avatar,
  Drawer,
  Divider,
  Button,
  Stack,
  Radio,
  FormControlLabel,
  Popover,
  IconButton,
  Chip,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Cookies from "js-cookie";
import { fetchData, getAccessFromToken } from "../../utilities/tokenUtils";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import LogoutIcon from "@mui/icons-material/Logout";
import { getmasterdatalabels, getUserDetials } from "../../apiCalls/ApiCalls";
import toggleButton from "../../assets/images/sidebar-simple.svg";
import { isEmpty } from "lodash";
import { TranslationsContext } from "../TranslationsContext";

const History = (props) => {
  const { setLanguage, translations } = useContext(TranslationsContext);
  const {
    isHistory,
    anchorgpt,
    setAnchorgpt,
    setTheme,
    color,
    themeDarkColor,
    toggleDrawer,
  } = props;
  const themeValue = localStorage.getItem("themeValue");
  const [userDetails, setUserDetails] = useState({
    FullName: " - ",
    Contact: " - ",
    JobTitle: " - ",
    Email: " - ",
    EmpId: " - ",
  });
  const [spaceName, SetSpaceName] = useState();

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValue, setSelectedValue] = React.useState(
    themeValue !== null ? themeValue : "a"
  );
  const [openTheme, setOpenTheme] = useState(false);
  const [themeAnchorEl, setThemeAnchorEl] = useState(null);
  const [category, setCategory] = useState();

  const fetchSpaceDetails = async () => {
    try {
      const formattedSpaceData = await fetchData("End-User");

      setCategory(formattedSpaceData);
    } catch (error) {
      console.error("Error fetching master data:", error);
    }
  };

  const getmasterdata = async () => {
    try {
      const formattedmasterdata = await getmasterdatalabels(category);

      SetSpaceName(formattedmasterdata.data);
    } catch (error) {
      console.error("Error fetching master data:", error);
    }
  };

  useEffect(() => {
    fetchSpaceDetails();
  }, []);

  useEffect(() => {
    if (category?.length > 0) {
      getmasterdata();
    }
  }, [category]);

  useEffect(() => {
    getEmployeeDetails();
  }, []);

  useEffect(() => {
    localStorage.setItem("themeValue", selectedValue);
  }, [selectedValue]);

  const getEmployeeDetails = async () => {
    const { EmpId } = getAccessFromToken();
    const response = await getUserDetials(EmpId);
    if (response?.status === 200) {
      const responseData = response?.data;
      setUserDetails(responseData);
    } else {
      setUserDetails({
        FullName: "User",
        Contact: "",
        JobTitle: "",
        Email: "",
        EmpId: "",
      });
    }
  };

  const handleOpenTheme = (event) => {
    setThemeAnchorEl(event.currentTarget);
    setOpenTheme(!openTheme);
  };

  const openProfile = (event) => {
    setOpen((previousOpen) => !previousOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    Cookies.remove("jwtToken", {
      domain: process.env.REACT_APP_COOKIES_DOMAIN,
      path: "/",
    });
    Cookies.remove("username");
    Cookies.remove("userrole");
    Cookies.remove("userEmail", {
      domain: process.env.REACT_APP_COOKIES_DOMAIN,
      path: "/",
    });
    Cookies.remove("access", {
      domain: process.env.REACT_APP_COOKIES_DOMAIN,
      path: "/",
    });
    const authAppUrl = process.env.REACT_APP_AUTH_URI;
    const redirectUri = encodeURIComponent(process.env.REACT_APP_REDIRECT_URI);
    window.location.href = `${authAppUrl}?redirect_uri=${redirectUri}&app=nexus`;
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "spring-popper" : undefined;

  const items = [
    { value: "English", text: "English" },
    { value: "Spanish", text: "Española" },
    { value: "French", text: "Français" },
    { value: "German", text: "Deutsch" },
    { value: "Telugu", text: "తెలుగు" },
    { value: "Hindi", text: "हिन्दी" },
    { value: "Tamil", text: "தமிழ்" },
    { value: "Malayalam", text: "മലയാളം" },
    { value: "Chinese", text: "中国人" },
    { value: "Russian", text: "Русский" },
    { value: "Japanese", text: "日本語" },
    { value: "Korean", text: "한국인" },
    { value: "Arabic", text: "عربي" },
    { value: "Italian", text: "Italiana" },
  ];
  const [selectedLanguage, setSelectedLanguage] = useState(
    Cookies.get("selectedLanguage") || "English"
  );
  const [openLang, setOpenLang] = useState(false);
  const [acnchorLangE1, setAnchorLangE1] = useState(null);
  const openTranslate = (event) => {
    setAnchorLangE1(event.currentTarget);
    setOpenLang((previous) => !previous);
  };
  const handleLanguage = async (value) => {
    setSelectedLanguage(value);
    setOpenLang(false);
    await setLanguage(value);
  };

  const drawerContent = (
    <Grid
      sx={{
        color: "text.primary",
        backgroundColor: `${color}`,
        padding: "20px",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: "text.primary",
          gap: 1,
          justifyContent: "space-between",
        }}
      >
        <Stack direction={"row"}>
          <Typography
            sx={{ fontSize: "32px", fontWeight: 700, fontFamily: "Oxygen" }}
          >
            Nexus
          </Typography>
          <AutoAwesomeIcon
            sx={{ color: themeDarkColor, width: "36px", height: "36px" }}
          />
        </Stack>
        <IconButton
          title="Open Sidebar"
          disableRipple
          onClick={() => toggleDrawer(false)}
        >
          <img
            src={toggleButton}
            alt="toggle-button"
            style={{
              width: "28px",
              height: "28px",
            }}
          />
        </IconButton>
      </Box>

      <Box
        sx={{
          overflowY: "auto",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography>
          <b>{translations?.history || "History"}</b>{" "}
          {translations?.coming_soon || "Coming soon..!"}
        </Typography>
        <Typography
          sx={{
            textDecoration: "underline",
            fontSize: "14px",
            cursor: "pointer",
          }}
          onClick={() => {
            sessionStorage.removeItem("chatHistory");
            window.location.reload();
          }}
        >
          {translations?.clear_chat || "clear chat"}
        </Typography>
      </Box>

      <Box>
        <Divider sx={{ mb: 4 }} />
        {/* Profile Footer */}
        <Box
          sx={{
            display: "flex",
            mb: "20px",
            mt: "10px",
            cursor: "pointer",
          }}
          onClick={openProfile}
        >
          <Avatar
            sx={{
              width: 30,
              height: 30,
              border: `1px solid ${themeDarkColor}`,
              backgroundColor: `${themeDarkColor}80`,
              backdropFilter: "blur(40px)",
            }}
          >
            <Typography variant="h6">
              {userDetails?.FirstName?.split("")[0]}
            </Typography>
          </Avatar>
          <Typography variant="h6" marginLeft={1}>
            {userDetails?.FirstName}
          </Typography>
        </Box>
        {/* Profile box */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          onClose={() => setOpen(false)}
        >
          <Box
            sx={{
              maxWidth: "max-content",
              border: `2px solid ${themeDarkColor}30`,
              borderRadius: 1,
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={2}
              sx={{
                backgroundColor: "#000",
                p: 1,
                pt: 0,
                color: "#fff",
                borderRadius: "4px 4px 0px 0px",
              }}
            >
              <Avatar
                sx={{
                  width: "40px",
                  height: "40px",
                  bottom: "-20px",
                  border: `5px solid ${themeDarkColor}`,
                  backgroundColor: `${themeDarkColor}80`,
                  backdropFilter: "blur(40px)",
                }}
              >
                <Typography variant="h5">
                  {userDetails?.FirstName?.split("")[0]}
                </Typography>
              </Avatar>
              <Stack direction={"column"} spacing={0}>
                <Typography fontSize={"16px"}>
                  {userDetails?.FirstName}
                </Typography>
              </Stack>
            </Stack>

            {/* Employee details */}

            <Stack
              direction={"column"}
              spacing={1}
              sx={{
                p: 1,
                pt: 2,
                pl: 4,
                // mt: 2,
                borderRadius: "0px 0px 4px 4px",
                backgroundColor: "background.default",
                color: "text.primary",
              }}
            >
              <Typography fontSize={"18px"} fontWeight={600}>
                {translations?.email_id || "Email ID"}
              </Typography>
              <Typography>{userDetails.Email}</Typography>
              <Typography fontSize={"18px"} fontWeight={600}>
                {translations?.your_spaces || "Your spaces"}
              </Typography>
              <Box
                sx={{
                  maxWidth: "200px",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 1,
                }}
              >
                {!isEmpty(spaceName) &&
                  spaceName?.map((eachSpace) => (
                    <Chip
                      key={eachSpace}
                      label={eachSpace}
                      variant="outlined"
                      size="small"
                    />
                  ))}
              </Box>
              {/* themes  */}
              <Button
                sx={{
                  alignSelf: "flex-start",
                  textAlign: "left",
                  textTransform: "none",
                  color: "unset",
                  display: "flex",
                  justifyContent: "space-between",
                  pl: 0,
                  fontSize: "16px",
                  "&:hover": { background: "unset" },
                }}
                disableRipple
                endIcon={<NavigateNextIcon />}
                fullWidth
                variant="text"
                onClick={(event) => handleOpenTheme(event)}
              >
                {translations?.choose_theme || "Choose Theme"}
              </Button>
              {/* Theme options  */}
              <Popover
                open={openTheme}
                anchorEl={themeAnchorEl}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
                onClose={() => setOpenTheme(false)}
              >
                <Stack
                  direction={"column"}
                  sx={{
                    backgroundColor: "background.default",
                    color: "text.primary",
                    borderRadius: 2,
                    p: 1,
                  }}
                >
                  <FormControlLabel
                    checked={selectedValue === "a"}
                    onClick={() => setTheme("Green")}
                    value="Green"
                    label="Green"
                    labelPlacement="end"
                    control={
                      <Radio
                        {...controlProps("a")}
                        sx={{ "&.Mui-checked": { color: "#84C101" } }}
                      />
                    }
                  />
                  <FormControlLabel
                    checked={selectedValue === "b"}
                    onClick={() => setTheme("Lavender")}
                    value="Lavender"
                    label="Lavender"
                    labelPlacement="end"
                    control={
                      <Radio
                        {...controlProps("b")}
                        sx={{ "&.Mui-checked": { color: "#7800B3" } }}
                      />
                    }
                  />
                  <FormControlLabel
                    checked={selectedValue === "c"}
                    onClick={() => setTheme("Orange")}
                    value="Orange"
                    label="Orange"
                    labelPlacement="end"
                    control={
                      <Radio
                        {...controlProps("c")}
                        sx={{ "&.Mui-checked": { color: "#ff5722" } }}
                      />
                    }
                  />
                  <FormControlLabel
                    checked={selectedValue === "d"}
                    onClick={() => setTheme("Dark")}
                    value="Dark"
                    label="Dark"
                    labelPlacement="end"
                    control={<Radio {...controlProps("d")} color="default" />}
                  />
                </Stack>
              </Popover>
              {/* multiLanguage  */}
              <Button
                sx={{
                  alignSelf: "flex-start",
                  textAlign: "left",
                  textTransform: "none",
                  color: "unset",
                  display: "flex",
                  justifyContent: "space-between",
                  pl: 0,
                  fontSize: "16px",
                  "&:hover": { background: "unset" },
                }}
                disableRipple
                endIcon={<NavigateNextIcon />}
                fullWidth
                variant="text"
                onClick={(event) => openTranslate(event)}
              >
                {translations?.choose_language || "Choose Language"}
              </Button>
              <Popover
                open={openLang}
                anchorEl={acnchorLangE1}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
                onClose={() => setOpenLang(false)}
              >
                <Box
                  sx={{
                    maxWidth: "max-content",
                    border: "0.2px solid #8DDD67",
                  }}
                >
                  <ul
                    style={{
                      listStyleType: "none",
                      padding: 0,
                      margin: 0,
                      height: "220px",
                      overflowY: "auto",
                      width: "140px",
                      scrollbarWidth: "thin",
                    }}
                  >
                    {items.map((item) => (
                      <li
                        key={item.value}
                        onClick={() => handleLanguage(item.value)}
                        style={{
                          backgroundColor:
                            selectedLanguage === item.value
                              ? "#A1DD70"
                              : "transparent",
                          cursor: "pointer",
                          padding: "8px",
                          marginBottom: "2px",
                        }}
                      >
                        {item.text}
                      </li>
                    ))}
                  </ul>
                </Box>
              </Popover>

              <Divider variant="middle" color={themeDarkColor} />
              <Button
                disableFocusRipple
                disableRipple
                sx={{
                  pl: 0,
                  alignSelf: "start",
                  textTransform: "none",
                  color: "unset",
                  fontSize: "18px",
                  "&:hover": {
                    backgroundColor: "#fff",
                  },
                }}
                onClick={handleLogout}
                variant="text"
                startIcon={
                  <LogoutIcon
                    sx={{
                      color: themeDarkColor,
                      // ml: 3,
                      height: "28px",
                      width: "28px",
                    }}
                  />
                }
              >
                {translations?.logout || "Logout"}
              </Button>
            </Stack>
          </Box>
        </Popover>
      </Box>
    </Grid>
  );

  return (
    <>
      {!isHistory && <>{drawerContent}</>}
      {isHistory && (
        <Grid>
          <Drawer
            elevation={"0"}
            open={anchorgpt}
            onClose={() => setAnchorgpt(false)}
          >
            {drawerContent}
          </Drawer>
        </Grid>
      )}
    </>
  );
};

export default History;
