import axios from "axios";
import { API_TIMEOUT } from "../utilities/constants";
import Cookies from "js-cookie";

const token = Cookies.get("jwtToken");

const AxiosInstance = (baseURL) => {
  const initAxiosInstance = () => {
    const options = {
      baseURL: baseURL,
      timeout: API_TIMEOUT,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const axiosInstance = axios.create(options);
    return axiosInstance;
  };

  return initAxiosInstance();
};

export default AxiosInstance;
