import { Outlet } from "react-router-dom";
import { getLocalAccessToken } from "./tokenUtils";
const ProtectedRoute = () => {
  const handleLogin = () => {
    const authAppUrl = process.env.REACT_APP_AUTH_URI;
    const redirectUri = encodeURIComponent(process.env.REACT_APP_REDIRECT_URI);
    window.location.href = `${authAppUrl}?redirect_uri=${redirectUri}&app=nexus`;
  };

  const token = getLocalAccessToken();
  return token === undefined ? handleLogin() : <Outlet />;
};
export default ProtectedRoute;
