import { Typography } from "@mui/material";
import React, { useContext } from "react";
import { Box } from "@mui/system";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { TranslationsContext } from "../TranslationsContext";

const GptAssist = ({ themeColor, themeDarkColor }) => {
  const { translations } = useContext(TranslationsContext);
  const applyGradients = () => {
    switch (themeColor) {
      case "#eeffe9":
        return `linear-gradient(90deg, #84C101 50% , #8DDD67 70%)`;
      case "#FFE3C8":
        return `linear-gradient(90deg, #FF9A51 50% , #FFC289 70%)`;
      case "#D4D8FF":
        return `linear-gradient(90deg, #7800B3 50% , #989582 70%)`;
      case "#424242":
        return `linear-gradient(90deg, #000000 50% , #000000 70%)`;
      default:
        return `linear-gradient(90deg, #84C10110 , #8DDD6790)`;
    }
  };

  return (
    <Box>
      <Typography
        sx={{
          fontWeight: "600",
          fontSize: "36px",
          textAlign: "center",
          fontFamily: "Red Hat Mono",
          lineHeight: "47.63px",
          letterSpacing: "0.06em",
        }}
      >
        <b style={{ fontFamily: "Oxygen", fontWeight: 700 }}>Nexus</b>
        <AutoAwesomeIcon
          sx={{
            color: themeDarkColor !== "#ffffff" ? themeDarkColor : "#000000",
            width: "36px",
            height: "36px",
          }}
        />
      </Typography>
      <Typography
        variant="h6"
        sx={{
          fontWeight: "600",
          fontSize: "32px",
          textAlign: "center",
          letterSpacing: "2%",
          background: applyGradients(),
          fontFamily: "poppins",
          WebkitBackgroundClip: "text",
          backgroundClip: "text",
          color: "transparent",
        }}
      >
        {translations.how_can_i_help_you ||
          "Hello How can I can help You Today ?"}
      </Typography>
    </Box>
  );
};

export default GptAssist;
