import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import GptUi from "./components/gptui/GptUi";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotFoundComponent from "./components/NotFoundComponent";
import ProtectedRoute from "./utilities/protectedRoute";
import UnauthorizedLayout from "./components/UnauthorizedLayout";
import { TranslationsProvider } from "./components/TranslationsContext";

function App() {
  const [darkMode, setDarkMode] = useState(false);

  const theme = createTheme({
    typography: {
      fontFamily: "Work Sans, sans-serif",
    },
    palette: {
      mode: darkMode ? "dark" : "light",
    },
  });
  const toggleDarkMode = (value) => {
    if (value === "dark") {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  };

  return (
    <BrowserRouter>
      <TranslationsProvider>
        <ToastContainer />
        <ThemeProvider theme={theme}>
          <Routes>
            <Route exact path="/" element={<ProtectedRoute />}>
              <Route
                exact
                path="/"
                element={<GptUi setAppTheme={toggleDarkMode} />}
              />
            </Route>
            <Route path="/unauthorized" element={<UnauthorizedLayout />} />
            <Route path="/*" element={<NotFoundComponent />} />
          </Routes>
        </ThemeProvider>
      </TranslationsProvider>
    </BrowserRouter>
  );
}

export default App;
